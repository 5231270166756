<template>
	<div class="transmit_sub_container">
		<div class="transmittal_slip_filter adj_sur">
			<div class="label_element_cover">
				<label>Filter By Request Date</label>
				<flat-pickr class="date_for_pos_fixed" v-model="daterange" :config="configDate" placeholder="Date"
					name="birthdate" @on-close="dateChanged" @on-open="cleardatepicker"></flat-pickr>
			</div>
			<!-- <div class="label_element_cover">
				<label>Filter By Carrier</label>
				<select v-model="provider_id" @change="fetchPostalDocument">
					<option value="all">All</option>
					<option v-for="(carrier, i) in carriers" :key="'carriers' + i" :value="carrier.id">{{ carrier.name }}
					</option>
				</select>
			</div> -->
			<div class="label_element_cover">
				<label>Filter By Document Type</label>
				<select v-model="postal_document_type_id" @change="filterStatus">
					<option value="all">All</option>
					<option v-for="(postaldocument, i) in postaldocuments" :key="'postaldocuments' + i"
						:value="postaldocument.id">{{ postaldocument.postal_document_type }}</option>
				</select>
			</div>


		</div>

		<section class="print_hide">
			<div class="two_tabs">
				<a class="tab_buttons active">
					POSTAL & PRE-PAID SERVICES
					<span class="label">24</span>
				</a>
			</div>
			<div class="jobs_table">
				<table>
					<tr>
						<th>REQUEST DATE</th>
						<!-- <th>CARRIER</th> -->
						<th>COST CENTER</th>
						<th>REQUESTER NAME</th>
						<th>POSTAL DOCUMENT TYPE</th>
						<th>ACTION</th>
					</tr>
					<tbody v-if="!loading">
						<tr v-for="(data, i) in prepaid" :key="i">
							<td>
								<p class="text-center">{{ moment(data.request_date).format("DD-MM-YYYY") }}</p>


							</td>
							<!-- <td>
								<p><b>{{ data.carrier_name }}</b></p>
							</td> -->
							<td>
								<!-- <p>Needed by :<b>{{ moment(data.needed_by_date).format("DD-MM-YYYY") }}</b></p> -->
								<template v-if="'kwm' == data.client || !data.client">
									<template v-if="!data.custom_code">
										<p v-if="data.matter_number">Matter Number: {{data.matter_number.matter_number}}</p>
										<p v-if="!data.matter_number">GL Code: {{data.gl_code}}</p>
									</template>
									<template v-else>
										<p>{{data.custom_code}}: {{data.custom_code_value}}</p>
									</template>
								</template>
								<template v-else>
									<p>{{  capitalizeFirstLetter(data.client) }}</p>
								</template>
							</td>
							<!-- <td>
              <p>{{data.cost_center_name}}</p>
              <p>Needed by :<b>{{moment(data.needed_by_date).format("DD-MM-YYYY")}}</b></p>
            </td> -->
							<td>
								<p><b>{{ data.requester_name }}</b></p>

							</td>
							<td>
								<p style="color: red;"><b>{{ data.postal_document_type && data.postal_document_type.postal_document_type }}</b></p>
								<p>Requested Qty:<b>{{ data.requested_qty }}</b></p>
								<template v-if="data.status === 'Issued'">
									<p>Issue Qty:<b>{{ data.issue_qty }}</b></p>
									<p>Issue By:<b>{{ data.issued_by }}</b></p>
									<p>Issue Date:<b v-if="data.issue_by_date">{{ moment(data.issue_by_date).format("DD-MM-YYYY") }}</b></p>
								</template>
								<p v-if="1 == data.references.length">Request Reference: <b>{{ data.references[0].reference_number }}</b></p>
								<p>Total Price:<b>{{ (data.total_price).toFixed(2) }}</b></p>
							</td>
							<td>
								<div class="icons_wrapper">
									<a>
										<i @click="deleteTransaction(data.id)" v-if="(auth().user_type =='hub_admin' && isToday(data.created_at)) || (auth().user_type =='user' && data.status != 'Issued')"
											class="material-icons red" title="Delete">delete</i>
										<i class="material-icons grey" title="Could not be deleted" v-else>delete</i>

									</a>
								</div>
								<a class="bt bt_black">{{ data.status }}</a>
								<a class="bt bt_gold" v-if="auth().user_type == 'hub_admin' && data.status == 'Requested'"
									@click="updatestatus(data)">ISSUE ITEM</a>
								<template v-if="1 < data.references.length && auth().user_type == 'hub_admin' && data.status == 'Issued'">
									<a class="bt bt_gold" @click="updatestatus(data)">View References</a>
								</template>

							</td>

						</tr>
					</tbody>
				</table>
				<p v-if="!loading && prepaid.length == 0">No data found</p>
			</div>
			<loader v-if="loading" />
			<paginate v-if="pages > 0" v-show="!loading" v-model="current_page" :page-count="pages"
				active-class="paginate-active" :click-handler="fetchTransaction" prev-link-class="paginate-previous"
				next-link-class="paginate-next" :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'"
				:no-li-surround="true">
			</paginate>
		</section>

		<div class="black-overlay" v-if="popupdatestatus">
			<div class="box-modal smallPops">
				<div class="body">
					<popUpdatePostalCount @closeModal="popupdatestatus = ''" :data="popupdatestatus"
						v-if="popupdatestatus" />
				</div>
			</div>
		</div>

	</div>
</template>
<script>
import popUpdatePostalCount from "./popups/popUpdatePostalCount.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
	name: "ViewPendingTransaction",
	components: {
		flatPickr,
		popUpdatePostalCount
	},
	data() {
		return {
			popupdatestatus: "",
			daterange: moment(new Date()).format('YYYY-MM-DD'),
			postal_document_type_id: "all",
			transaction_id: "",
			provider_id: "all",
			enableTime: true,
			configDate: {
				mode: "range",
				format: "d-m-Y",
				altFormat: "d-m-Y",
				altInput: true
			},
			carriers: [],
			postaldocuments: [],
			prepaid: [],
			pages: 0,
			current_page: 0,
			post_data: {
				from: moment(new Date()).format("YYYY-MM-DD"),
				to: moment(new Date()).format("YYYY-MM-DD"),
				documentTypeFilter: "",
				carrierFilter: "",
			},
			loading: true
		};
	},
	created() {
		this.$emit("breadCrumbData", ["Postal Services", " View Postal Services"]);
		this.fetchCarrier();
		this.fetchPostalDocument();
		this.fetchTransaction();

	},
	methods: {
		dateChanged(daterange) {
			this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
			this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
			this.fetchTransaction();
		},
		cleardatepicker() {

			this.daterange = "";
		},

		filterStatus() {
			this.post_data.documentTypeFilter = this.postal_document_type_id;
			this.fetchTransaction();
		},

		fetchCarrier() {
			this.axios.get("/api/provider/carrierlist/")
				.then(response => {
					this.carriers = response.data.providers;
				})
				.catch(error => {
					this.toast.error();
				});
		},

		fetchPostalDocument(){
		  this.axios.get("/api/postaldocument")
		  .then(response => {
		      this.postaldocuments = response.data.postal_document_types;

		  })
		  .catch(error =>{
		      this.toast.error();
		  });
		},

		// fetchPostalDocument() {
		// 	if (this.provider_id) {
		// 		this.axios.get("/api/postaldocument/carrier/" + this.provider_id)
		// 			.then(response => {
		// 				this.postaldocuments = response.data.postal_document_types;
		// 				this.post_data.carrierFilter = this.provider_id;
		// 				this.fetchTransaction();

		// 			})
		// 			.catch(error => {
		// 				this.toast.error();
		// 			});
		// 	}
		// },
		fetchTransaction(page = 1) {
			this.loading = true;
			this.current_page = page;
			this.axios
				.post("/api/postalPrepaid/" + page + "/view", this.post_data)
				.then(response => {
					this.prepaid = response.data.postalprepaid.list.map(element => {
						element.is_edit_mode = false;
						return element;
					});
					this.pages = response.data.postalprepaid.pages;
					this.loading = false;

				})
				.catch(error => {
					this.loading = false;
					console.log(error);
				});
		},
		deleteTransaction(id) {
			if (confirm("Are you sure you want to delete this postal & pre-paid solution?")) {
				this.axios
					.delete("/api/postalPrepaid/" + id)
					.then(response => {
						this.toast.success(response.data.msg);
						this.fetchTransaction();
					})
					.catch(error => {
						console.log(error);
					});
			}
		},

		updatestatus(data) {
			this.popupdatestatus = data;
			// this.axios.post("/api/postalPrepaid/status/"+id,this.post_data)
			// .then(response => {
			//    this.fetchTransaction();
			// })
			// .catch(error =>{
			//     console.log(error);
			// });
		},
		capitalizeFirstLetter(str) {

			if(!str || str == '') {
				return '';
			}

			return str.replace(/\b\w/g, function(char) {
				return char.toUpperCase();
			});
		},
		isToday(timestamp) {
			const givenDate = new Date(timestamp).toDateString();
			const today = new Date().toDateString();
			return givenDate === today;
		}


	}
};
</script>
<style lang="scss">
$theme-color: #06A5ED;

.jobs_table {
	border-top: 5px solid $theme-color;

	table {
		width: 100%;
		border-collapse: collapse;
		border-left: 1px solid #ccc;
		font-size: 14px;
		margin-bottom: 15px;

		tr {
			th {
				background: #004a7c;
				padding: 20px 10px;
				color: #ccc;
				font-weight: 700;
				border: 1px solid #222;
				border-left: 0;
				border-top: 0;
				text-align: left;
			}

			td {
				background: #eee;
				padding: 20px 10px;
				color: #333;
				font-weight: 400;
				border: 1px solid #ccc;
				border-left: 0;
				border-top: 0;
				text-align: left;
				vertical-align: top;

				p {
					padding: 0 0 10px 0;

					b {
						font-weight: 700;
					}
				}

				p.text-center {
					text-align: center;
				}

				span.label {
					display: inline-block;
					font-weight: 700;
					background: #000;
					color: #fff;
					border-radius: 4px;
					padding: 5px;
					text-align: center;
					margin-bottom: 5px;

					&.green {
						background: #46aa42;
					}
				}

				.icons_wrapper {
					padding: 10px 0;
					display: flex;
					justify-content: space-between;
					position: relative;

					a {
						display: inline-block;
						width: 24px;
						height: 24px;

						.material-icons {
							font-size: 18px;
							position: relative;
							top: 0;
							margin: 0;
							font-weight: normal;

							&.blue {
								color: rgb(45, 91, 241);
							}

							&.red {
								color: rgb(241, 74, 45);
							}

							&.green {
								color: #46aa42;
							}

							&.orange {
								color: orange;
							}

							&.grey {
								color: grey;
							}

						}
					}
				}

				.bt {
					width: 120px;
					height: 40px;
					text-align: center;
					line-height: 40px;
					border-radius: 4px;
					display: block;
					margin: 5px 0;
				}

				.bt_black {
					background: #063657;
					color: #fff;
				}

				.bt_gold {
					background: $theme-color;
					color: #000;
				}
			}
		}
	}
}

.two_tabs {
	padding: 40px 10px 0 10px;
	display: flex;
	justify-content: space-around;

	.tab_buttons {
		display: block;
		width: 40%;
		height: 50px;
		text-align: center;
		line-height: 50px;
		background: #ececec;
		color: #333;
		border: 1px solid #ddd;

		span.label {
			display: none;
			background: $theme-color;
			color: #000;
			padding: 5px 10px;
			border-radius: 4px;
		}
	}

	.tab_buttons.active {
		background: #004a7c;
		color: #fff;
	}
}

.view_optons_wrapper {
	width: 240px;
	height: auto;
	background: #fff;
	border: 1px solid #ccc;
	box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
	position: absolute;
	left: -250px;
	top: 10px;
	padding: 10px;

	a.view_option_button {
		width: 100% !important;
		height: 40px !important;
		background: $theme-color;
		color: #000;
		display: block;
		margin-bottom: 10px;
		text-align: center;
		line-height: 40px;
	}

	a.view_option_button:last-child {
		background: #aaa;
		color: #000;

		margin-bottom: 0;
	}
}</style>

